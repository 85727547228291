import React from "react";
import { PageProps } from "gatsby";
import { Title } from "../components";
// To prevent TypeScript errors on the css prop on arbitrary elements
// https://styled-components.com/docs/api#usage-with-typescript
import _cssprop from "styled-components/cssprop";
import "modern-css-reset";

const Home: React.FC<PageProps> = () => (
  <main
    css={`
      height: 100vh;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    `}
  >
    <Title>Revision.</Title>
  </main>
);

export default Home;
