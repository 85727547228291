import React from "react";

const Title: React.FC<{}> = ({ children }) => {
  return (
    <header
      css={`
        font-size: 96px;
        font-family: "Unica One";
      `}
    >
      {children}
    </header>
  );
};

export default Title;
